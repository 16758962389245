import React from 'react';
import { services } from '../../data/services';

import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';

import BasePage from './BasePage';

const service: any = services.find((s) => s.title === 'Cargo Insurance');

const CargoInsurance: React.FC = () => {
  // const classes = useStyles();

  return (
    <BasePage title={service.title}>
      <Typography>
        {service.description} Therefore we offer cargo insurance solutions to
        add more peace of mind to your business by keeping you protected from
        any potential logistics-related risks. You will be covered for
        unexpected scenarios with our full spectrum of customized insurance
        plans so that you can enjoy a host of benefits and worry-free shipments
        every time.
      </Typography>
      <ul>
        <li>Minimize Risks</li>
        <li>Affordable Premium</li>
        <li>Comprehensive Coverage</li>
        <li>Professional Support & Ease of Coordination</li>
      </ul>
    </BasePage>
  );
};

// const useStyles = makeStyles((theme: any) => ({
//   root: {},
// }));

export default CargoInsurance;
