import React from 'react';

import { Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Section from '../../components/Section';

import CultureItems from './CultureItems';
import Values from './Values';

const Culture: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <div className={classes.background} />
      </Hidden>
      <div className={classes.content}>
        <Section>
          <Typography variant="h4" color="secondary" gutterBottom>
            At Interfreight, it's all about customer's satisfaction, how to meet
            and exceed their expectations.
          </Typography>
          <Typography variant="body1">
            Customer demands are the driving forces behind our development, we
            continuously create long term value for customers by being
            responsive to customer's needs and demands. We measure our work
            against how much value we bring to customers, because we can only
            succeed through our customer's success. We contribute ourselves to
            our customer's success, customer's success is our victory.
          </Typography>
        </Section>
        <CultureItems />
        <Values />
        <Section>
          <Typography variant="h4" color="secondary" gutterBottom>
            Deliver care, Deliver excellence
          </Typography>
          <Typography variant="body1">
            Our business thrives on our customer's growth and success, we are
            contributed to our customer's success by providing professional,
            tailor-made logistics solutions, loving-care and excellent service.
            We carry their shipments, we care their shipments. We serve them
            under promise, we deliver them excellence beyond their expectations.
          </Typography>
        </Section>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7ff',
    [theme.breakpoints.down('sm')]: {
      background: 'url("/img/culture.jpeg") no-repeat bottom left #f5f7ff',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
    },
  },
  background: {
    background: 'url("/img/culture.jpeg") no-repeat top center white',
    backgroundSize: 'cover',
    height: 500,
    width: '100%',
  },
  content: {
    marginTop: -300,
    maxWidth: 800,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
      maxWidth: 'unset',
    },
  },
}));

export default Culture;
