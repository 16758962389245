import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { services } from '../../data/services';

const Listing: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h2" color="secondary" gutterBottom>
        Our Services
      </Typography>
      <Grid container spacing={3} className={classes.items}>
        {services.map(service => (
          <Grid
            key={service.title}
            item
            xs={12}
            sm={6}
            md={4}
            className={classes.item}
          >
            <RouterLink to={service.url} className={classes.service}>
              <ButtonBase color="primary" className={classes.buttonBase}>
                <Card className={classes.card}>
                  <div className={classes.backgroundWrapper}>
                    <div
                      className={classes.background}
                      style={{
                        background: `url("${
                          service.imgUrl
                        }") no-repeat center center white`,
                        backgroundSize: 'cover',
                      }}
                    />
                  </div>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.icon}>
                      <img src={service.icon} alt={service.title} />
                    </div>
                    <div>
                      <Typography
                        className={classes.cardTitle}
                        color="secondary"
                      >
                        {service.title}
                      </Typography>
                      <Typography>{service.description}</Typography>
                    </div>
                  </CardContent>
                </Card>
              </ButtonBase>
            </RouterLink>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8, 0),
    background: '#f5f7ff',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 0),
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  items: {
    maxWidth: 1000,
    padding: theme.spacing(4, 2),
    justifyContent: 'center',
  },
  item: {
    flexGrow: 1,
  },
  service: {
    display: 'flex',
    height: '100%',
  },
  backgroundWrapper: {
    position: 'relative',
    height: 200,
    width: '100%',
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    transition: '3s ease',
  },
  buttonBase: {
    height: '100%',
    textAlign: 'left',
    display: 'block',
    color: 'rgb(255,100,0)',
    '&:hover $background': {
      transform: 'scale(1.05)',
    },
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    padding: theme.spacing(1, 2),
    '& img': {
      width: 50,
    },
  },
  cardContent: {
    display: 'flex',
    paddingLeft: 0,
  },
  cardTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
  },
}));

export default Listing;
