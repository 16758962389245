import React from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Header from './Header';
import SideSelector from './SideSelector';

const BasePage: React.FC<any> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div>
      <Header title={title} />
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2} className={classes.noSpacing} />
          <Grid item xs={12} md={8} className={classes.centerContent}>
            <div className={classes.pageContent}>{children}</div>
          </Grid>
          <Grid item xs={12} md={2}>
            <SideSelector selection={title} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  content: {
    flexGrow: 1,
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: 1000,
    padding: theme.spacing(4, 2),
    '& li': {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  centerContent: {
    backgroundColor: 'white',
  },
  noSpacing: {
    [theme.breakpoints.down('md')]: {
      padding: '0 !important',
    },
  },
  pageContent: {
    '& > p': {
      fontSize: '1.1rem',
      paddingBottom: theme.spacing(3),
    },
  },
}));

export default BasePage;
