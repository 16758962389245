import React from 'react';
import { services } from '../../data/services';

import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';

import BasePage from './BasePage';

const service: any = services.find(s => s.title === 'E-commerce Logistics');

const ECommerce: React.FC = () => {
  // const classes = useStyles();

  return (
    <BasePage title={service.title}>
      <Typography>
        {service.description} Outsourcing logistics allows e-commerce
        sellers to centralize resources and concentrate energy on their core
        business, i.e. selling more on Amazon or eBay, and promoting their
        web-store and brand. To help cross-border e-commerce sellers stay
        competitive, Interfreight offers a total logistics solution to
        e-commerce regardless of their size of business.
      </Typography>
      <ul>
        <li>International Sea freight (FCL & LCL), Air freight</li>
        <li>Amazon FBA Forwarding</li>
        <li>Adapt to all trade terms: from Ex-work to DDP</li>
        <li>Customs Compliance</li>
        <li>Global Inventory Deployment, Inventory Re-placement</li>
        <li>Express deliveries: time-sensitive, high-value goods</li>
        <li>Amazon delivery</li>
        <li>Warehousing and Order Fulfillment</li>
        <li>
          Value-added service: Labeling, Palletizing, Repacking, Return Handling
        </li>
      </ul>
    </BasePage>
  );
};

// const useStyles = makeStyles((theme: any) => ({
//   root: {},
// }));

export default ECommerce;
