import React from "react";
import { services } from "../../data/services";

import { Typography } from "@material-ui/core";
// import { makeStyles } from '@material-ui/styles';

import BasePage from "./BasePage";

const service: any = services.find(
  (s) => s.title === "Warehouse and Distribution"
);

const WarehouseAndDistribution: React.FC = () => {
  // const classes = useStyles();

  return (
    <BasePage title={service.title}>
      <Typography>
        {service.description} Our aim is to minimize transportation and
        inventory costs and bring added value to our client’s business. We
        employ a full range of warehousing service in all major metropolitan
        areas and gateway ports throughout US and Canada. You can stay flexible
        and avoid holding warehousing space that may not be fully utilized. Only
        use and pay for warehouse space that supports your supply change
        strategy, and free up working capital for your core business.
      </Typography>
      <ul>
        <li>Trans loading / Cross-docking</li>
        <li>Deconsolidation</li>
        <li>Export or Domestics Distribution Center</li>
        <li>Long or short-term Storage</li>
        <li>Inventory management and control</li>
        <li>QA & QC at receiving</li>
        <li>Sorting/Segregation, Pick and pack</li>
        <li>Labeling, tagging and ticketing</li>
        <li>Barcode Scanning</li>
        <li>Rework: re-packing, re-labeling, re-branding, re-boxing</li>
        <li>
          Kitting / Pairing: Bundle multiple SKUs / items into a ready-to-sell
          package, Show-case
        </li>
        <li>Palletizing, Lashing, Dunnage</li>
        <li>Container Loading Survey, Cargo Inspection on-site</li>
        <li>Pick & pack by order, SKU etc.</li>
        <li>Return management</li>
      </ul>
    </BasePage>
  );
};

// const useStyles = makeStyles((theme: any) => ({
//   root: {},
// }));

export default WarehouseAndDistribution;
