import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const HomeCallToAction: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h4" className={classes.message}>
          Start your journey with
        </Typography>
        <Typography variant="h3" className={classes.message}>
          Interfreight Logistics
        </Typography>
      </div>
      <div className={classes.links}>
        <div className={classes.link}>
          <RouterLink to="/contact">
            <Button variant="outlined">Contact Us</Button>
          </RouterLink>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: '#f5f7ff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(8, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2),
    },
  },
  message: {
    color: '#333',
    fontWidth: 'bold',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    maxWidth: 1000,
  },
  links: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    padding: theme.spacing(2),
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      color: '#333',
      borderColor: '#333',
    },
  },
}));

export default HomeCallToAction;
