import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  Assignment as AssignmentIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
} from '@material-ui/icons';

const Landing: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.introBackground} />
      <div className={classes.introContent}>
        <Grid container justify="flex-end">
          <div className={classes.introOverlay} />
          <Grid item xs={12} sm={6} className={classes.section}>
            <Typography variant="h2" color="secondary" gutterBottom>
              Contact Us
            </Typography>
            <Typography>
              <a href="tel:6265078119">
                <PhoneIcon className={classes.icon} />
                <b>USA</b> (626) 507-8119
              </a>
              <a href="tel:7787280556">
                <PhoneIcon className={classes.icon} />
                <b>Canada</b> (778) 728-0556
              </a>
            </Typography>
            <Typography>
              <a href="mailto:aminda@in-frt.com">
                <EmailIcon className={classes.icon} />
                aminda@in-frt.com
              </a>
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.requestForQuote}
            >
              <Link to="/request-a-quote">
                <AssignmentIcon className={classes.icon} />
                Request a Quote
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    '& b': {
      fontWeight: 'bold',
      marginRight: theme.spacing(2),
    },
  },
  introOverlay: {
    position: 'absolute',
    background: 'white',
    opacity: 0.8,
    top: 0,
    bottom: 0,
    left: '50%',
    right: 0,
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      left: 0,
    },
  },
  introBackground: {
    background: 'url("/img/contact-us.jpeg") no-repeat center center #333',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -2,
  },
  introContent: {
    padding: theme.spacing(8, 8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2),
    },
    '& p': {
      fontSize: '1.5rem',
      marginBottom: theme.spacing(2),
    },
    '& a': {
      textDecoration: 'none',
      color: '#333',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: 'max-content',
      '&:hover': {
        color: 'rgb(255,100,0)',
      },
    },
  },
  requestForQuote: {
    fontWeight: 'bold',
  },
  icon: {
    height: 20,
    paddingRight: theme.spacing(1),
  },
  address: {
    fontStyle: 'normal',
    fontSize: 12,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(16, 16),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(12, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 4),
    },
    '& a': {
      textDecoration: 'none',
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    '& button': {
      color: '#333',
    },
  },
}));

export default Landing;
