import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  Button,
  CircularProgress,
  Paper,
  Grid,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Header from './Header';

const RequestAQuote: React.FC = () => {
  const classes = useStyles();

  const steps = ['Company Information', 'Shipping Information', 'Review'];
  const [activeStep, setActiveStep] = React.useState(0);

  const [company, setCompany] = useState('');
  const [personName, setPersonName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');

  const [typeOfShipment, setTypeOfShipment] = useState('');
  const [originLocation, setOriginLocation] = useState('');
  const [originPort, setOriginPort] = useState('');
  const [destinationLocation, setDestinationLocation] = useState('');
  const [destinationPort, setDestinationPort] = useState('');
  const [modeRequested, setModeRequested] = useState('');
  const [cargoDescription, setCargoDescription] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');

  const formRef = useRef(null);
  const [errors, setErrors] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [reCaptchaResponse, setReCaptchaResponse] = useState('');

  const storeCaptcha = (captchaValue: string) => {
    setIsVerified(true);
    setReCaptchaResponse(captchaValue);
  };

  const handleSubmit = (index: number) => {
    if (index === steps.length - 1 && !isLoading) {
      setIsLoading(true);
      fetch(
        'https://83xhj8ss10.execute-api.us-west-2.amazonaws.com/prod/form',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            reCaptchaResponse,
            type: 'Request for Quote',
            title: '',
            formData: [
              {
                title: 'Company Information',
                items: [
                  { key: 'Company', value: company },
                  { key: 'Name', value: personName },
                  { key: 'Job Title', value: jobTitle },
                  { key: 'Phone Number', value: phoneNumber },
                  { key: 'Email', value: email },
                  { key: 'Street', value: street },
                  { key: 'City', value: city },
                  { key: 'State', value: state },
                  { key: 'Zip', value: zip },
                  { key: 'Country', value: country },
                ],
              },
              {
                title: 'Shipping Information',
                items: [
                  { key: 'Type of Shipment', value: typeOfShipment },
                  { key: 'Origin Location', value: originLocation },
                  { key: 'Origin Port', value: originPort },
                  { key: 'Dest. Location', value: destinationLocation },
                  { key: 'Dest. Port', value: destinationPort },
                  { key: 'Mode Requested', value: modeRequested },
                  { key: 'Cargo Description', value: cargoDescription },
                  {
                    key: 'Additional Information',
                    value: additionalInformation,
                  },
                ],
              },
            ],
          }),
        },
      )
        .then((res: any) => res.json())
        .then((json: any) => {
          if (json.error) {
            setErrors([json.error]);
          } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
          }
        })
        .catch(() => {
          setErrors(['Something went wrong, please try again']);
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setErrors(null);
    setIsVerified(false);
    setReCaptchaResponse('');
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <div className={classes.group}>
              <TextValidator
                name="company"
                label="Company"
                value={company}
                onChange={(event: any) => setCompany(event.target.value)}
                validators={['required']}
                errorMessages={['company is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                name="personName"
                label="Your Name"
                value={personName}
                onChange={(event: any) => setPersonName(event.target.value)}
                validators={['required']}
                errorMessages={['your name is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                name="jobTitle"
                label="Job Title"
                value={jobTitle}
                onChange={(event: any) => setJobTitle(event.target.value)}
                validators={['required']}
                errorMessages={['job title is required']}
                variant="filled"
                margin="dense"
              />
            </div>
            <div className={classes.group}>
              <TextValidator
                name="phoneNumber"
                label="Phone Number"
                value={phoneNumber}
                onChange={(event: any) => setPhoneNumber(event.target.value)}
                validators={['required']}
                errorMessages={['phone number is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                type="email"
                name="email"
                label="Email"
                value={email}
                onChange={(event: any) => setEmail(event.target.value)}
                validators={['required']}
                errorMessages={['email is required']}
                variant="filled"
                margin="dense"
              />
            </div>
            <div className={classes.group}>
              <TextValidator
                name="street"
                label="Street"
                value={street}
                onChange={(event: any) => setStreet(event.target.value)}
                validators={['required']}
                errorMessages={['street is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                name="city"
                label="City"
                value={city}
                onChange={(event: any) => setCity(event.target.value)}
                validators={['required']}
                errorMessages={['city is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                name="state"
                label="State"
                value={state}
                onChange={(event: any) => setState(event.target.value)}
                validators={['required']}
                errorMessages={['state is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                name="zip"
                label="Zip"
                value={zip}
                onChange={(event: any) => setZip(event.target.value)}
                validators={['required']}
                errorMessages={['zip is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                name="country"
                label="Country"
                value={country}
                onChange={(event: any) => setCountry(event.target.value)}
                validators={['required']}
                errorMessages={['country is required']}
                variant="filled"
                margin="dense"
              />
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className={classes.group}>
              <TextValidator
                name="typeOfShipment"
                label="Type of Shipment"
                value={typeOfShipment}
                onChange={(event: any) => setTypeOfShipment(event.target.value)}
                validators={['required']}
                errorMessages={['type of shipment is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                name="modeRequested"
                label="Mode Requested"
                value={modeRequested}
                onChange={(event: any) => setModeRequested(event.target.value)}
                validators={['required']}
                errorMessages={['mode requested is required']}
                variant="filled"
                margin="dense"
              />
            </div>
            <div className={classes.group}>
              <TextValidator
                name="originLocation"
                label="Origin Location"
                value={originLocation}
                onChange={(event: any) => setOriginLocation(event.target.value)}
                validators={['required']}
                errorMessages={['origin location is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                name="originPort"
                label="Origin Port"
                value={originPort}
                onChange={(event: any) => setOriginPort(event.target.value)}
                validators={['required']}
                errorMessages={['origin port is required']}
                variant="filled"
                margin="dense"
              />
            </div>
            <div className={classes.group}>
              <TextValidator
                name="destinationLocation"
                label="Dest. Location"
                value={destinationLocation}
                onChange={(event: any) =>
                  setDestinationLocation(event.target.value)
                }
                validators={['required']}
                errorMessages={['dest. location is required']}
                variant="filled"
                margin="dense"
              />
              <TextValidator
                name="destinationPort"
                label="Dest. Port"
                value={destinationPort}
                onChange={(event: any) =>
                  setDestinationPort(event.target.value)
                }
                validators={['required']}
                errorMessages={['destination port is required']}
                variant="filled"
                margin="dense"
              />
            </div>
            <div className={classes.group}>
              <TextValidator
                name="cargoDescription"
                label="Cargo Description"
                value={cargoDescription}
                onChange={(event: any) =>
                  setCargoDescription(event.target.value)
                }
                validators={['required']}
                errorMessages={['cargo description is required']}
                multiline={true}
                rows={2}
                rowsMax={4}
                variant="filled"
                margin="dense"
              />
            </div>
            <div className={classes.groupLg}>
              <TextValidator
                name="additionalInformation"
                label="Tell us more about your shipment"
                value={additionalInformation}
                onChange={(event: any) =>
                  setAdditionalInformation(event.target.value)
                }
                multiline={true}
                rows={4}
                rowsMax={6}
                variant="filled"
                margin="dense"
              />
            </div>
          </>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Company Information
              </Typography>
              <table className={classes.table}>
                <tbody>
                  <tr>
                    <td>
                      <Typography>Company</Typography>
                    </td>
                    <td>
                      <Typography>{company}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Person</Typography>
                    </td>
                    <td>
                      <Typography>{personName}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Job Title</Typography>
                    </td>
                    <td>
                      <Typography>{jobTitle}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Phone Number</Typography>
                    </td>
                    <td>
                      <Typography>{phoneNumber}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Email</Typography>
                    </td>
                    <td>
                      <Typography>{email}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Address</Typography>
                    </td>
                    <td>
                      <Typography>{street}</Typography>
                      <Typography>
                        {city}, {state} {zip}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Country</Typography>
                    </td>
                    <td>
                      <Typography>{country}</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Shipping Information
              </Typography>
              <table className={classes.table}>
                <tbody>
                  <tr>
                    <td>
                      <Typography>Type of Shipment</Typography>
                    </td>
                    <td>
                      <Typography>{typeOfShipment}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Origin Location</Typography>
                    </td>
                    <td>
                      <Typography>{originLocation}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Origin Port</Typography>
                    </td>
                    <td>
                      <Typography>{originPort}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Dest. Location</Typography>
                    </td>
                    <td>
                      <Typography>{destinationLocation}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Mode Requested</Typography>
                    </td>
                    <td>
                      <Typography>{modeRequested}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Cargo Description</Typography>
                    </td>
                    <td>
                      <Typography>{cargoDescription}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Additional Shipping Information</Typography>
                    </td>
                    <td>
                      <Typography>{additionalInformation}</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12}>
              <ReCAPTCHA
                sitekey="6LelZKQUAAAAAD_Sch1OeFE1bxngJG1sU8zqObnO"
                onChange={storeCaptcha}
              />
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <Header title="Request a Quote" />
      <div className={classes.content}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          className={classes.stepper}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <ValidatorForm
                  ref={formRef}
                  onSubmit={() => handleSubmit(index)}
                  className={classes.form}
                >
                  {getStepContent(index)}
                  <div className={classes.actionsContainer}>
                    <div className={classes.buttons}>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={() =>
                            setActiveStep(prevActiveStep => prevActiveStep - 1)
                          }
                          className={classes.button}
                        >
                          Back
                        </Button>
                      </div>
                      <div className={classes.buttonWrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                          disabled={
                            isLoading ||
                            (activeStep === steps.length - 1 && !isVerified)
                          }
                        >
                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                          {activeStep === steps.length - 1 && isLoading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            {errors && (
              <Typography>{errors.map((error: string) => error)}</Typography>
            )}
            {!errors && (
              <Typography>
                Thank you, your request for a quote has been processed. We will
                get back to you shortly.
              </Typography>
            )}
            <Button
              onClick={handleReset}
              className={classes.button}
              variant="outlined"
            >
              Send Another Request
            </Button>
          </Paper>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    margin: 'auto',
    maxWidth: 1000,
    padding: theme.spacing(4, 2),
  },
  stepper: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  group: {
    marginBottom: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  groupLg: {
    width: 375,
    '& > *': {
      width: '100%',
    },
  },
  table: {
    padding: theme.spacing(1),
    '& td': {
      verticalAlign: 'top',
      padding: theme.spacing(0.5, 1),
      '&:first-child p': {
        fontSize: 12,
      },
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default RequestAQuote;
