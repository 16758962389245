import React from 'react';

import { makeStyles } from '@material-ui/styles';

const BackgroundCubesEffect: React.FC = () => {
  const classes = useStyles();

  return (
    <ul className={classes.bubbles}>
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
    </ul>
  );
};

const useStyles = makeStyles((theme: any) => ({
  bubbles: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    margin: 0,

    '& li': {
      position: 'absolute',
      listStyle: 'none',
      display: 'block',
      width: 40,
      height: 40,
      backgroundColor: 'rgba(255,100,0,0.1)',
      bottom: '-160px',
      WebkitAnimation: '$square 25s infinite',
      animation: '$square 25s infinite',
      WebkitTransitionTimingFunction: 'linear',
      transitionTimingFunction: 'linear',

      '&:nth-child(1)': {
        left: '10%',
      },
      '&:nth-child(2)': {
        left: '20%',
        width: 80,
        height: 80,
        animationDelay: '2s',
        animationDuration: '17s',
      },
      '&:nth-child(3)': {
        left: '25%',
        animationDelay: '4s',
      },
      '&:nth-child(4)': {
        left: '40%',
        width: 60,
        height: 60,
        animationDuration: '22s',
        backgroundColor: 'rgba(255,100,0,0.2)',
      },
      '&:nth-child(5)': {
        left: '70%',
      },
      '&:nth-child(6)': {
        left: '80%',
        width: 120,
        height: 120,
        animationDelay: '3s',
        backgroundColor: 'rgba(255,100,0,0.15)',
      },
      '&:nth-child(7)': {
        left: '32%',
        width: 160,
        height: 160,
        animationDelay: '7s',
      },
      '&:nth-child(8)': {
        left: '55%',
        width: 20,
        height: 20,
        animationDelay: '15s',
        animationDuration: '40s',
      },
      '&:nth-child(9)': {
        left: '25%',
        width: 10,
        height: 10,
        animationDelay: '2s',
        animationDuration: '40s',
        backgroundColor: 'rgba(255,100,0,0.25)',
      },
      '&:nth-child(10)': {
        left: '90%',
        width: 160,
        height: 160,
        animationDelay: '11s',
      },
    },
  },
  '@keyframes square': {
    '0%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(-700px) rotate(600deg)' },
  },
}));

export default BackgroundCubesEffect;
