import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BackgroundCubesEffect from '../../components/BackgroundCubesEffect';

const HomeIntro: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.message}>
        <div className={classes.messageContent}>
          <Typography className={classes.messageOne}>
            You can depend on Interfreight Logistics as your logistics partner
          </Typography>
          <Typography className={classes.messageTwo}>
            We know that each shipment is important to your business
          </Typography>
        </div>
      </div>
      <BackgroundCubesEffect />
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: 'relative',
  },
  message: {
    backgroundColor: '#f5f7ff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(16, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 2),
    },
  },
  messageContent: {
    maxWidth: 600,
  },
  messageOne: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '2.5rem',
    color: '#333',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  },
  messageTwo: {
    color: '#333',
    fontSize: '1.25rem',
  },
}));

export default HomeIntro;
