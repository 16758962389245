import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Section from '../../components/Section';

const ITEMS = [
  {
    title: 'Customer Oriented',
    description: `We begin each day with our customer's logistics goals in mind. This
      process starts by being attentive and listening to what our
      customers require. Sometimes this means going beyond standard
      operating procedures, and instead, proactively taking steps to
      fulfill our customer's objectives.`,
  },
  {
    title: 'Trustworthy',
    description: `The true worth of our service is the trust that we earn from 
      our customers. As we aspire to maintain and build upon this trust, each 
      operation carries the highest level of integrity. 
      We are committed to upholding your trust in us.`,
  },
  {
    title: 'Excellence-seeking',
    description: `Each stakeholder, internal and external, share the desire to serve in
      our industry and for our customers. We take great pride in our
      excellence, but we are always striving to improve. This is an
      important trait that we expect from everyone within our organization.`,
  },
  {
    title: 'Our Core values',
  },
  {
    title: 'Innovative',
    description: `Being responsive to change is critical in our evolving business
      landscape. We seek every challenging situation as an opportunity to
      creatively move forward, and become a positive agent of change.`,
  },
  {
    title: 'Accomplish',
    description: `Together, our partners and associates work best to create value for
      each stakeholder. We collaborate to produce logistics support that is
      professional and efficient, yet seamless for our customers.`,
  },
  {
    title: 'Caring',
    description: `We appreciate the opportunity to serve our customers. And we are
      thankful that we have remarkable partners and associates who provide
      this service. Our good fortune is a source for both thankfulness and
      greater diligence in our efforts.`,
  },
];

const MAP = [[0, 1], [2, 3, 4], [5, 6]];

const Values: React.FC = () => {
  const classes = useStyles();

  return (
    <Section>
      <Typography variant="h3" color="secondary" align="center">
        Our Core Values
      </Typography>
      <div className={classes.hexagons}>
        {MAP.map((row, rowIndex) => (
          <div key={rowIndex} className={classes.hexagonRow}>
            {row.map((item, itemIndex) => (
              <div
                key={itemIndex}
                className={[
                  classes.hexagonContainer,
                  ITEMS[item].description ? classes.hexagonContainerHover : '',
                ].join(' ')}
              >
                {ITEMS[item].description && (
                  <div className={classes.hexagonWrapper}>
                    <div
                      className={[classes.hexagon, classes.hexagonInner].join(
                        ' ',
                      )}
                    />
                  </div>
                )}
                <div className={classes.hexagonWrapper}>
                  <div className={classes.hexagon} />
                </div>
                <div
                  className={[
                    classes.hexagonContent,
                    ITEMS[item].description ? '' : classes.hide,
                  ].join(' ')}
                >
                  <Typography className={classes.hexagonTitle}>
                    {ITEMS[item].title}
                  </Typography>
                  <Typography className={classes.hexagonDescription}>
                    {ITEMS[item].description}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Section>
  );
};

const HEX_SPACING = 10;
const HEX_COLOR = 'white';
const HEX_WIDTH = 200;
const HEX_HEIGHT = `calc(${HEX_WIDTH}px / 1.73)`;
const HEX_MARGIN = `calc(${HEX_WIDTH}px / 2)`;
const HEX_BORDER = `calc(${HEX_MARGIN} / 1.73)`;

const useStyles = makeStyles((theme: any) => ({
  root: {},
  hexagons: {
    '& p': {
      fontSize: '1.15rem',
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      width: '100%',
      maxWidth: 800,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: HEX_WIDTH - HEX_SPACING * 8,
      marginBottom: theme.spacing(8),
      '& > div': {
        marginTop: `calc(-${HEX_WIDTH - HEX_SPACING * 8}px / 2)`,
      },
    },
  },
  hexagonRow: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  hexagonContainerHover: {
    [theme.breakpoints.up('md')]: {
      cursor: 'pointer',
      '&:hover $hexagon': {
        height: `calc(${HEX_WIDTH * 1.75}px / 1.73)`,
        width: HEX_WIDTH * 1.75,
        '&:before, &:after': {
          borderLeft: `calc(${HEX_WIDTH * 1.75}px / 2) solid transparent`,
          borderRight: `calc(${HEX_WIDTH * 1.75}px / 2) solid transparent`,
        },
        backgroundColor: 'rgb(255,100,0)',
        '&:before': {
          borderBottom: `calc(calc(${HEX_WIDTH *
            1.75}px / 2) / 1.73) solid ${HEX_COLOR}`,
          borderBottomColor: 'rgb(255,100,0)',
        },
        '&:after': {
          borderTop: `calc(calc(${HEX_WIDTH *
            1.75}px / 2) / 1.73) solid ${HEX_COLOR}`,
          borderTopColor: 'rgb(255,100,0)',
        },
      },
      '&:hover $hexagonTitle': {
        opacity: 0,
      },
      '&:hover $hexagonDescription': {
        transition: 'all 1s ease',
        opacity: 1,
      },
      '&:hover $hexagonWrapper': {
        zIndex: 20,
      },
      '&:hover $hexagonContent': {
        zIndex: 30,
      },
    },
  },
  hexagonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      margin: 10,
      justifyContent: 'center',
      width: HEX_WIDTH,
      height: (HEX_WIDTH / 1.73) * 2,
    },
  },
  hexagonWrapper: {
    zIndex: 1,
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hexagon: {
    [theme.breakpoints.up('sm')]: {
      zIndex: 2,
      alignItems: 'center',
      backgroundColor: HEX_COLOR,
      display: 'flex',
      flexDirection: 'column',
      fill: 'white',
      height: HEX_HEIGHT,
      justifyContent: 'center',
      margin: `${HEX_BORDER} 0`,
      position: 'relative',
      transition: 'all .2s ease',
      width: HEX_WIDTH,
      pointerEvents: 'none',
      '&:before, &:after': {
        borderLeft: `${HEX_MARGIN} solid transparent`,
        borderRight: `${HEX_MARGIN} solid transparent`,
        content: '""',
        left: 0,
        position: 'absolute',
        transition: 'all .2s ease',
        width: 0,
      },
      '&:before': {
        borderBottom: `${HEX_BORDER} solid ${HEX_COLOR}`,
        bottom: '100%',
      },
      '&:after': {
        borderTop: `${HEX_BORDER} solid ${HEX_COLOR}`,
        top: '100%',
        width: 0,
      },
    },
  },
  hexagonInner: {
    backgroundColor: 'rgb(255,100,0)',
    '&:before': {
      borderBottomColor: 'rgb(255,100,0)',
    },
    '&:after': {
      borderTopColor: 'rgb(255,100,0)',
    },
    transform: 'scale(1.03)',
  },
  hexagonContent: {
    [theme.breakpoints.up('md')]: {
      zIndex: 3,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  hexagonTitle: {
    color: 'rgb(255,100,0)',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      margin: 0,
      fontWeight: 'bold',
      textAlign: 'center',
      position: 'absolute',
      width: HEX_WIDTH,
      padding: theme.spacing(2),
    },
  },
  hexagonDescription: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      color: 'white',
      width: HEX_WIDTH * 1.75,
      padding: theme.spacing(4),
      opacity: 0,
    },
  },
  hide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default Values;
