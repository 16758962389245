import React from 'react';
import { services } from '../../data/services';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BasePage from './BasePage';

const service: any = services.find((s) => s.title === 'Custom Brokerage');

const CustomBrokerage: React.FC = () => {
  const classes = useStyles();

  return (
    <BasePage title={service.title}>
      <Typography>
        {service.description} As a C-TPAT member and an AMS provider, our custom
        brokerage services reduce your risk of noncompliance and guide you
        through the appropriate tariffs and duties.
      </Typography>
      <Typography>
        For greater efficiency, custom brokerage should be integrated with our
        freight forwarding services. We employ field experts to ensure that your
        shipments comply with local customs regulations in USA and Canada.
        Interfreight Logistics stays abreast of local government regulations and
        pronouncements, so that you can stay focused on your core business.
      </Typography>
      <ul>
        <li>Declarations, Clearances, and Compliance</li>
        <li>AMS, ISF, AES filing in USA and ACI/eManifest filing in Canada</li>
        <li>Classification Consultation</li>
        <li>Import & Export</li>
      </ul>
      <div className={classes.logos}>
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <img src="/img/company-img-1-1.jpg" alt="CTPAT Logo" />
          </Grid>
          <Grid item>
            <img
              src="/img/us_customs_and_border_protection_seal.png"
              alt="US Customs and Border Protection Seal"
            />
          </Grid>
        </Grid>
      </div>
    </BasePage>
  );
};

const useStyles = makeStyles((theme: any) => ({
  logos: {
    marginTop: theme.spacing(4),
    '& img': {
      margin: theme.spacing(2),
      height: 110,
    },
  },
}));

export default CustomBrokerage;
