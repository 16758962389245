import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const HomeCompanyInfo: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.message}>
        <div className={classes.messageContent}>
          <Typography className={classes.messageOne}>
            Established and headquartered in Los Angeles, CA, Interfreight
            Logistics is an integrated logistics service provider for both
            imports and exports to/from North America. We have developed our own
            branches covering main ports of USA and Canada and expanded
            worldwide agent network encompassing hundreds of ports and cities in
            over 60 countries and regions including China and other Asia Pacific
            countries, Europe, Mediterranean Sea, Black Sea, Red Sea, Middle
            East and Oceania.
          </Typography>
          <div className={classes.link}>
            <RouterLink to="/about">
              <Button variant="outlined">Learn More About Us</Button>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(16, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 2),
    },
  },
  messageContent: {
    zIndex: 2,
    maxWidth: 600,
  },
  messageOne: {
    fontSize: '1.5rem',
  },
  effect: {
    position: 'relative',
    height: 400,
  },
  link: {
    marginTop: theme.spacing(2),
    '& a': {
      textDecoration: 'none',
    },
  },
}));

export default HomeCompanyInfo;
