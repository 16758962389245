import oceanFreightIcon from '../icons/024-shipping.svg';
import airFreightIcon from '../icons/001-Air Freight.svg';
import customBrokerageIcon from '../icons/005-checklist.svg';
import eCommerceLogisticsIcon from '../icons/020-online shop.svg';
import warehouseAndDistributionIcon from '../icons/019-logistics.svg';
import internationalMovingServiceIcon from '../icons/030-worldwide.svg';

export const services = [
  {
    type: 'logistics',
    icon: oceanFreightIcon,
    url: '/services/ocean-freight',
    title: 'Ocean Freight',
    description:
      'As a Non-Vessel Operating Common Carrier (FMC NVOCC), Interfreight Logistics provides the flexibility to choose the right ocean freight solution for your business.',
    imgUrl: '/img/logistics-ocean-freight.jpeg',
  },
  {
    type: 'logistics',
    icon: airFreightIcon,
    url: '/services/air-freight',
    title: 'Air Freight',
    description:
      'When calling for airfreight, we know that you need special attention for your critical shipment.',
    imgUrl: '/img/logistics-air-freight.jpeg',
    imgPosition: 'center calc(50% + 175px)',
  },
  {
    type: 'logistics',
    icon: customBrokerageIcon,
    url: '/services/custom-brokerage',
    title: 'Custom Brokerage',
    description:
      'Efficient customs clearance and full compliance with security requirements and regulations are key steps to a seamless logistics and supply chain management.',
    imgUrl: '/img/logistics-custom-brokerage.jpeg',
  },
  {
    type: 'logistics',
    icon: eCommerceLogisticsIcon,
    url: '/services/e-commerce',
    title: 'E-commerce Logistics',
    description:
      'Online selling has been developing faster and faster in recent years; while the completion is also getting more and more intense.',
    imgUrl: '/img/logistics-e-commerce.jpeg',
  },
  {
    type: 'logistics',
    icon: warehouseAndDistributionIcon,
    url: '/services/warehouse-and-distribution',
    title: 'Warehouse and Distribution',
    description:
      'Interfreight recognizes effective and efficient warehousing and distribution strategy is critical to our clients’ success in today’s changing environment.',
    imgUrl: '/img/logistics-warehouse.jpeg',
  },
  {
    type: 'logistics',
    icon: internationalMovingServiceIcon,
    url: '/services/international-moving-service',
    title: 'International Moving Service',
    description:
      'We combine our global presence with the local expertise to provide safe & smooth international moving services and deliver a pleasant and stress-free experience to customers worldwide.',
    imgUrl: '/img/logistics-international-moving.jpeg',
  },
  {
    type: 'logistics',
    icon: customBrokerageIcon,
    url: '/services/cargo-insurance',
    title: 'Cargo Insurance',
    description:
      'We understand your concerns about  safety of your cargo in transit.',
    imgUrl: '/img/cargo-insurance.jpg',
  },
];
