import React from 'react';
import { services } from '../../data/services';

import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';

import BasePage from './BasePage';

const service: any = services.find(s => s.title === 'Ocean Freight');

const OceanFreight: React.FC = () => {
  // const classes = useStyles();

  return (
    <BasePage title={service.title}>
      <Typography>
        {service.description} We collaborate with all leading
        carriers worldwide, allowing for a deep and flexible array of options to
        choose among ocean freight providers. You don’t need to bother with
        freight allocation or be locked into a few carriers. Simply choose among
        the best combination of cost efficiency and speed of delivery to suit
        your needs.
      </Typography>
      <Typography>
        Our global networks allow us to offer reliable and timely transport
        choices on all trade lanes worldwide. We work in partnership with an
        array of ocean carriers to offer our customers flexible services and
        adapt very quickly to the changing market. Our strong relationships with
        carriers enable us to safeguard capacity, offer flexible schedule, and
        keep rates competitive.
      </Typography>
      <ul>
        <li>Full Container Loads (FCL)</li>
        <li>Less than Container Load (LCL)</li>
        <li>Consolidation / Groupage</li>
        <li>Special Containers: Flat-rack, Plat-form, Open-top, GOH</li>
        <li>Out-of-Gauge cargo</li>
        <li>Reefer/Temperature Controlled </li>
        <li>Multimodal transportation (Water+ Rail + Roadway)</li>
      </ul>
    </BasePage>
  );
};

// const useStyles = makeStyles((theme: any) => ({
//   root: {},
// }));

export default OceanFreight;
