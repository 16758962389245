import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

function NextArrow(props: any) {
  const { style, onClick } = props;
  return (
    <div style={{ ...style, ...arrowBaseStyle, left: 0 }} onClick={onClick}>
      <ChevronLeftIcon />
    </div>
  );
}

function PrevArrow(props: any) {
  const { style, onClick } = props;
  return (
    <div style={{ ...style, ...arrowBaseStyle, right: 0 }} onClick={onClick}>
      <ChevronRightIcon />
    </div>
  );
}

function transformLine(line: any, classes: any): any {
  return (
    <div className={classes.line}>
      {line.split(' ').map((word: string, wordIndex: number) => (
        <div key={wordIndex} className={['word', classes.word].join(' ')}>
          {word.split('').map((character: string, characterIndex: number) => (
            <span
              key={`${wordIndex}-${characterIndex}`}
              className={['char', classes.char].join(' ')}
            >
              {character}
            </span>
          ))}
          &nbsp;
        </div>
      ))}
    </div>
  );
}

const HomeCarousel: React.FC = () => {
  const classes = useStyles();

  const slides = useRef(
    [
      {
        lineOne: 'We match the right solution to your unique requirements',
        lineTwo: 'You can depend on Interfreight Logistics',
        imgSrc: '/img/home-carousel-1.jpeg',
      },
      {
        lineOne:
          'Your dedicated account manager provides 24/7 service coverage, working with you each step of the way',
        lineTwo: 'You are our top priority',
        imgSrc: '/img/home-carousel-2.jpeg',
      },
      {
        lineOne: 'We stay abreast of the ever changing environment,',
        lineTwo: 'so that you can stay focused on your core business',
        imgSrc: '/img/home-carousel-3.jpeg',
      },
    ].map(slide => ({
      ...slide,
      lineOneTransformed: transformLine(slide.lineOne, classes),
      lineTwoTransformed: transformLine(slide.lineTwo, classes),
    })),
  );

  const animateActiveSlide = useRef(() => {
    const activeSlides = document.getElementsByClassName('slick-active');
    if (activeSlides.length > 0) {
      const activeSlide = activeSlides[0];
      const lines = activeSlide.getElementsByClassName('line') as any;
      animateLines(lines);
    }
  });

  const resetSlides = () => {
    const words = document.getElementsByClassName('word');
    for (let i = 0; i < words.length; i++) {
      words[i].classList.remove('animate');
    }
  };

  const animateLines = (lines: any) => {
    const speed = 100;
    let counter = 0;
    for (let linesIndex = 0; linesIndex < lines.length; linesIndex++) {
      const line = lines[linesIndex];
      const words = line.getElementsByClassName('word');
      for (let wordsIndex = 0; wordsIndex < words.length; wordsIndex++) {
        setTimeout(() => {
          words[wordsIndex].classList.add('animate');
        }, counter * speed);
        counter++;
      }
      counter += 10;
    }
  };

  const beforeChange = () => {
    resetSlides();
  };

  const afterChange = () => {
    resetSlides();
    animateActiveSlide.current();
  };

  useEffect(() => {
    resetSlides();
    setTimeout(() => {
      animateActiveSlide.current();
    }, 1000);
  }, [animateActiveSlide]);

  return (
    <div className={classes.sliderWrapper}>
      <Slider
        dots={true}
        autoplay={false}
        autoplaySpeed={8000}
        infinite={true}
        speed={1000}
        slidesToShow={1}
        slidesToScroll={1}
        prevArrow={<NextArrow />}
        nextArrow={<PrevArrow />}
        beforeChange={beforeChange}
        afterChange={afterChange}
      >
        {slides.current.map((slide: any, index: number) => (
          <div key={index}>
            <div
              className={classes.content}
              style={{
                background: `url("${slide.imgSrc}") no-repeat center center`,
                backgroundSize: 'cover',
              }}
            >
              <div className={classes.text}>
                <div className="line">
                  <Typography className={classes.lineOne} component="div">
                    {slide.lineOneTransformed}
                  </Typography>
                </div>
                <div className="line">
                  <Typography className={classes.lineTwo} component="div">
                    {slide.lineTwoTransformed}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const arrowBaseStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255,255,255,0.1)',
  top: 0,
  bottom: 0,
  zIndex: 999,
  color: 'white',
  cursor: 'pointer',
};

const desktopHeight = 700;
const mobileHeight = 500;

const useStyles = makeStyles((theme: any) => ({
  sliderWrapper: {
    position: 'relative',
  },
  slider: {
    height: desktopHeight,
    [theme.breakpoints.down('md')]: {
      height: mobileHeight,
    },
  },
  content: {
    height: desktopHeight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(16, 8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 4),
      height: mobileHeight,
    },
  },
  text: {
    height: desktopHeight,
    maxWidth: 1000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      height: mobileHeight,
    },
  },
  line: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    transition: 'transform 1.5s ease',
  },
  word: {
    opacity: 0,
    whiteSpace: 'nowrap',
    '&.animate': {
      animation: '$an 1s ease-out 1 both',
      opacity: 1,
    },
  },
  char: {
    display: 'inline-block',
  },
  lineOne: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  lineTwo: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  '@keyframes an': {
    from: {
      opacity: 0,
      transform: 'translate(50px, -10px)',
    },
    to: {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
  },
}));

export default HomeCarousel;
