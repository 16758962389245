import React from 'react';

import { makeStyles } from '@material-ui/styles';

const Section: React.FC<any> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(7, 7),
    backgroundColor: 'white',
    flexGrow: 1,
    '& h4': {
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '2rem',
    },
    '& p': {
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 5),
      '& h4': {
        fontSize: '1.8rem',
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
      padding: theme.spacing(4, 2),
    },
  },
}));

export default Section;
