import React from 'react';
import { Route } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';

import AirFreight from './AirFreight';
import CustomBrokerage from './CustomBrokerage';
import ECommerce from './ECommerce';
import InternationalMovingService from './InternationalMovingService';
import OceanFreight from './OceanFreight';
import Listing from './Listing';
import WarehouseAndDistribution from './WarehouseAndDistribution';
import CargoInsurance from './CargoInsurance';

const Services: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Route component={Listing} path="/services" exact={true} />
      <Route component={AirFreight} path="/services/air-freight" />
      <Route component={CustomBrokerage} path="/services/custom-brokerage" />
      <Route component={ECommerce} path="/services/e-commerce" />
      <Route
        component={InternationalMovingService}
        path="/services/international-moving-service"
      />
      <Route component={OceanFreight} path="/services/ocean-freight" />
      <Route
        component={WarehouseAndDistribution}
        path="/services/warehouse-and-distribution"
      />
      <Route component={CargoInsurance} path="/services/cargo-insurance" />
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    width: '100%',
  },
}));

export default Services;
