import React from 'react';
import { Parallax, withController } from 'react-scroll-parallax';

import { makeStyles } from '@material-ui/styles';

const DividerSingleBackground: React.FC<any> = ({ parallaxController }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Parallax y={[-50, 50]}>
        <div className={classes.img} />
      </Parallax>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: 300,
    width: '100%',
    overflow: 'hidden',
  },
  img: {
    height: 600,
    width: '100%',
    background: 'url("/img/home-full-width-divider-1.jpeg") no-repeat center bottom',
    backgroundSize: 'cover',
  },
}));

export default withController(DividerSingleBackground);
