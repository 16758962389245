import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { services } from '../../data/services';

const HomeServices: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" color="secondary">
        Our Services
      </Typography>
      <Grid container spacing={3} className={classes.items}>
        {services.map(service => (
          <Grid
            key={service.title}
            item
            xs={12}
            sm={6}
            md={4}
            className={classes.item}
          >
            <RouterLink to={service.url} className={classes.service}>
              <div className={classes.icon}>
                <img src={service.icon} alt={service.title} />
              </div>
              <Typography className={classes.title} color="secondary">
                {service.title}
              </Typography>
            </RouterLink>
          </Grid>
        ))}
      </Grid>
      <div className={classes.link}>
        <RouterLink to="/services">
          <Button variant="outlined">Learn More About Our Services</Button>
        </RouterLink>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    padding: theme.spacing(16, 0),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 0),
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  items: {
    maxWidth: 1000,
    padding: theme.spacing(4, 2),
    justifyContent: 'center',
  },
  item: {
    flexGrow: 1,
  },
  service: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  icon: {
    padding: theme.spacing(3, 2),
    '& img': {
      width: 50,
    },
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
  },
  description: {},
  link: {
    marginTop: theme.spacing(2),
  },
}));

export default HomeServices;
