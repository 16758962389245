import React from 'react';
import { services } from '../../data/services';

import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';

import BasePage from './BasePage';

const service: any = services.find(s => s.title === 'Air Freight');

const AirFreight: React.FC = () => {
  // const classes = useStyles();

  return (
    <BasePage title={service.title}>
      <Typography>
        {service.description} Don't allow your valuable shipment to
        be lost among high volume shipments that receive more attention from
        larger freight forwarders. Interfreight Logistics specializes in guiding
        special airfreight orders, so your products get to their destination on
        time. You are our top priority.
      </Typography>
      <Typography>
        Interfreight has been working in partnerships with key air carriers for
        years, allowing us to offer our customers a choice of routings at highly
        competitive rates, giving access to whatever capacity you need and
        transit times that meet your shipment requirements. From picking-up in
        origin to final delivery to your door, our dedicated team will ensure
        your freight is handled with the utmost care and security.
      </Typography>
      <ul>
        <li>General cargo</li>
        <li>Oversize / overweight Solution</li>
        <li>Valuables transportation</li>
        <li>Air express transportation</li>
        <li>Cargo Insurance</li>
        <li>Door-to-Door Service</li>
      </ul>
    </BasePage>
  );
};

// const useStyles = makeStyles((theme: any) => ({
//   root: {},
// }));

export default AirFreight;
