import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { services } from '../../data/services';

const SideSelector: React.FC<any> = ({ selection }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Logistic Services</Typography>
      {services
        .filter((service: any) => service.type === 'logistics')
        .map((service: any) => (
          <Link to={service.url} key={service.title}>
            <Button className={classes.button}>{service.title}</Button>
          </Link>
        ))}
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: theme.spacing(1),
    color: 'rgb(255,100,0)',
  },
  button: {
    width: '100%',
    justifyContent: 'flex-start',
    '& span': {
      textAlign: 'left',
    },
    '&:hover': {
      color: 'rgb(255,100,0)',
    },
  },
}));

export default SideSelector;
