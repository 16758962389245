import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const RequestForQuoteHeader: React.FC<any> = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.headerText}>
        {/* <div>
          <Typography variant="subtitle1">Request for Quote</Typography>
        </div> */}
        <div>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  header: {
    background:
      'url("/img/quote-freight-forwarding.jpg") no-repeat center center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(8, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2),
    },
    '& h4, h6': {
      margin: 'auto',
      color: 'white',
    },
  },
  headerText: {
    width: '100%',
    maxWidth: 1000,
  },
}));

export default RequestForQuoteHeader;
