import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { AppBar, Button, IconButton, Toolbar, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';

const Navbar: React.FC = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const links = [
    { title: 'Home', url: '/' },
    { title: 'About', url: '/about' },
    { title: 'Culture', url: '/culture' },
    { title: 'Services', url: '/services' },
    { title: 'Contact', url: '/contact' },
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <RouterLink to="/" className={classes.title}>
            <img src="/img/company.png" alt="Interfreight Logistics" />
          </RouterLink>
          <img
            src="/img/logo-accent.png"
            alt="Interfreight Logistics message"
            className={classes.logoAccent}
          />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {links.map((link: any) => (
              <RouterLink to={link.url} key={link.title}>
                <Button>{link.title}</Button>
              </RouterLink>
            ))}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.sidebarHeader}>
          <img src="/img/company.png" alt="Interfreight Logistics" />
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        {links.map((link: any) => (
          <RouterLink
            to={link.url}
            key={link.title}
            onClick={() => setOpen(false)}
          >
            <Button color="inherit">{link.title}</Button>
          </RouterLink>
        ))}
      </Drawer>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    zIndex: 999,
    width: '100%',
    borderTop: '5px solid rgb(255,100,0)',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    display: 'none',
    marginLeft: -12,
    marginRight: 15,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  bars: {
    width: 20,
    height: 20,
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      width: 20,
      height: 2,
      marginTop: 4,
      marginBottom: 4,
      background: 'white',
    },
  },
  toolbar: {
    backgroundColor: 'white',
  },
  title: {
    padding: theme.spacing(2, 0),
    '& img': {
      height: 45,
      [theme.breakpoints.down('xs')]: {
        height: 30,
      },
    },
  },
  logoAccent: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  drawer: {
    padding: theme.spacing(1),
    width: 300,
    '& a': {
      textDecoration: 'none',
      width: '100%',
      '& button': {
        width: '100%',
      },
    },
  },
  sidebarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& img': {
      height: 25,
    },
  },
}));

export default Navbar;
