import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Email as EmailIcon, Phone as PhoneIcon } from '@material-ui/icons';

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.content}>
        <Grid item xs={12} sm={6} className={classes.section}>
          <Grid container spacing={2}>
            <Grid item direction="column" md={6} className={classes.section}>
              <div>
                <img
                  className={classes.title}
                  src="/img/company.png"
                  alt="Interfreight Logistics"
                />
              </div>
              <Typography component="div">
                <strong className={classes.addressCountry}>USA Address</strong>
                <a
                  href="https://www.google.com/maps/place/155+North+Riverview+Drive+%23105,+Anaheim,+CA+92808"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <address className={classes.address}>
                    155 N.Riverview Dr. Suite #105
                    <br />
                    Anaheim Hills, CA 92808
                  </address>
                </a>
                <br/>
                <strong className={classes.addressCountry}>
                  Canada Address
                </strong>
                <a
                  href="https://www.google.com/maps/place/%23720-999+West+Broadway,+Vancouver,+BC+V5Z+1K5"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <address className={classes.address}>
                    #720-999 West Broadway
                    <br />
                    Vancouver, BC V5Z 1K5
                  </address>
                </a>
              </Typography>
            </Grid>
            <Grid item md={6} className={classes.section}>
              <div>
                <Typography>
                  <a href="tel:6265078119">
                    <PhoneIcon className={classes.icon} />
                    <b>USA</b> (626) 507-8119
                  </a>
                  <a href="tel:7787280556">
                    <PhoneIcon className={classes.icon} />
                    <b>Canada</b> (778) 728-0556
                  </a>
                </Typography>
                <Typography>
                  <a href="mailto:aminda@in-frt.com">
                    <EmailIcon className={classes.icon} />
                    aminda@in-frt.com
                  </a>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.section}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography className={classes.column}>
                <RouterLink to="/">Home</RouterLink>
                <RouterLink to="/about">About</RouterLink>
                <RouterLink to="/culture">Culture</RouterLink>
                <RouterLink to="/services">Services</RouterLink>
                <RouterLink to="/contact">Contact</RouterLink>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.column}>
                <RouterLink to="/services" className={classes.navHeader}>
                  Services
                </RouterLink>
                <RouterLink to="/services/ocean-freight">
                  Ocean Freight
                </RouterLink>
                <RouterLink to="/services/air-freight">Air Freight</RouterLink>
                <RouterLink to="/services/custom-brokerage">
                  Custom Brokerage
                </RouterLink>
                <RouterLink to="/services/e-commerce">E-Commerce</RouterLink>
                <RouterLink to="/services/warehouse-and-distribution">
                  Warehouse and Distribution
                </RouterLink>
                <RouterLink to="/services/international-moving-service">
                  International Moving Service
                </RouterLink>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.column}>
                <RouterLink to="/contact" className={classes.navHeader}>
                  Contact Us
                </RouterLink>
                <RouterLink to="/request-a-quote">Request a Quote</RouterLink>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(4),
    borderBottom: '5px solid rgb(255,100,0)',
    backgroundColor: 'white',
    '& p': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& a': {
      color: 'rgba(0, 0, 0, 0.87)',
      textDecoration: 'none',
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      width: 'fit-content',
      '&:hover': {
        color: 'rgb(255,100,0)',
      },
    },
    '& b': {
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
    },
  },
  content: {
    margin: 'auto',
    maxWidth: 1200,
    justifyContent: 'center',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    height: 25,
    marginBottom: theme.spacing(2),
  },
  icon: {
    height: 20,
    paddingRight: theme.spacing(1),
  },
  addressCountry: {
    fontWeight: 'bold',
  },
  address: {
    fontStyle: 'normal',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  },
  navHeader: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(0.5),
  },
}));

export default Footer;
