import React from 'react';

import { Typography, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Section from '../../components/Section';

const COMPANY_LOGOS = [
  { imgSrc: '/img/company-img-1-1.jpg' },
  { imgSrc: '/img/company-img-1-2.png' },
  { imgSrc: '/img/company-img-1-3.png' },
  { imgSrc: '/img/company-img-2-1.jpg' },
  { imgSrc: '/img/company-img-2-2.jpg' },
  { imgSrc: '/img/company-img-2-3.jpg' },
  { imgSrc: '/img/company-img-2-4.jpg' },
  { imgSrc: '/img/company-img-2-5.jpg' },
];

const About: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <div className={classes.background} />
      </Hidden>
      <div className={classes.content}>
        <Section>
          <Typography variant="h4" color="secondary" gutterBottom>
            Our mission is to ensure that your products, from point of origin to
            destination, arrive on time and efficiently as possible.
          </Typography>
          <Typography variant="body1" gutterBottom>
            At your service, we deliver excellence. Over the years, we are
            guided by our core values, reliable customer service and
            professional integrity, in demonstrating our ability to serve
            diverse customers ranging from small eCommerce merchants to top
            Fortune 500 companies. From sea freight to air freight, from
            international/cross-boarder logistics to domestic transportation in
            USA and Canada, we can offer the right solution to satisfy your
            unique requirements. Your dedicated account manager provides this
            with excellent proactive communication and 24/7 service coverage,
            working with you each step of the way.
          </Typography>
        </Section>
        <Section>
          <Typography variant="body1" gutterBottom>
            Interfreight Logistics is a Non-Vessel Operating Common Carrier
            (NVOCC), certified by the Federal Maritime Commission (FMC) and
            Customs-Trade Partnership Against Terrorism (C-TPAT), and a member
            of WCA and JC-trans.
          </Typography>
          <div className={classes.icons}>
            <Grid container justify="center" spacing={2}>
              {COMPANY_LOGOS.map((logo, index) => (
                <Grid key={index} item>
                  <img src={logo.imgSrc} alt="company affiliated with" />
                </Grid>
              ))}
            </Grid>
          </div>
        </Section>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: 'relative',
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#f5f7ff',
    '& p': {
      fontSize: '1.5rem',
    },
    '& img': {
      margin: theme.spacing(2),
      height: 50,
    },
    [theme.breakpoints.down('sm')]: {
      background: 'url("/img/about-us.jpeg") no-repeat center center #f5f7ff',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
    },
  },
  background: {
    background: 'url("/img/about-us.jpeg") no-repeat center center #f5f7ff',
    backgroundSize: 'cover',
    height: 500,
    width: '100%',
  },
  content: {
    marginTop: -300,
    maxWidth: 800,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
      maxWidth: 'unset',
    },
  },
  icons: {
    marginTop: theme.spacing(4),
  },
}));

export default About;
