import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Home from './pages/Home/Home';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Culture from './pages/Culture/Culture';
import Contact from './pages/Contact/Contact';
import RequestAQuote from './pages/RequestAQuote/RequestAQuote';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

const App: React.FC = () => {
  const theme = createMuiTheme({
    palette: {
      primary: { main: 'rgb(50,50,150)' },
      secondary: { main: 'rgb(255,100,0)' },
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: ['Barlow Semi Condensed', 'monospace'].join(','),
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 0,
          '&:hover': {
            color: 'rgb(255,100,0)',
            borderColor: 'rgb(255,100,0)',
          },
        },
      },
      MuiPaper: {
        root: {
          borderRadius: '0 !important',
        },
      },
    },
  });

  const root = document.getElementById('root');

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider scrollContainer={root}>
        <CssBaseline />
        <Router>
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route component={Home} path="/" exact={true} />
            <Route component={About} path="/about" />
            <Route component={Culture} path="/culture" />
            <Route component={Services} path="/services" />
            <Route component={Contact} path="/contact" />
            <Route component={RequestAQuote} path="/request-a-quote" />
          </Switch>
          <Footer />
        </Router>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

export default App;
