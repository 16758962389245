import React from 'react';

import { makeStyles } from '@material-ui/styles';

import Carousel from './Carousel';
import Intro from './Intro';
// import Divider from './Divider';
import CompanyInfo from './CompanyInfo';
import Services from './Services';
// import HomeDividerTwo from './HomeDividerTwo';
import CallToAction from './CallToAction';
import DividerSingleBackground from '../../components/DividerSingleBackground';

const Home: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Carousel />
      <Intro />
      <CompanyInfo />
      {/* <Divider /> */}
      <DividerSingleBackground />
      <Services />
      {/* <HomeDividerTwo /> */}
      <CallToAction />
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
}));

export default Home;
