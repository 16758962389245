import React from 'react';

import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Section from '../../components/Section';

const ITEMS = [
  { title: 'Mission', description: 'To grow on mutual benefit!' },
  {
    title: 'Vision',
    description:
      'To give our partners/customers continuous and consistent loving care to ensure that together we enjoy sustainable growth and success.',
  },
  {
    title: 'Our Service Concept',
    description:
      'To be initiative, passionate, proactive,responsible and reliable.',
  },
  {
    title: 'Our Service Principle',
    description:
      'Cost effective, short cycle, one stop service, innovation of mutual benefit!',
  },
  {
    title: 'Business Goal',
    description:
      'Be a most reliable and sustainable supplier in logistics industry!',
  },
  {
    title: 'Corporate Values',
    description:
      'Caring, Customer-Oriented, Innovative, Trustworthy, Accomplishment, and Excellence-Seeking.',
  },
];

const CultureItems: React.FC = () => {
  const classes = useStyles();

  return (
    <Section>
      <Grid container className={classes.cultureItems}>
        {ITEMS.map((item, index) => (
          <Grid item md={6} key={index} className={classes.cultureItem}>
            <Typography
              color="secondary"
              variant="h5"
              className={classes.title}
            >
              {item.title}
              <div className={classes.dot} />
            </Typography>
            <Typography className={classes.description}>
              {item.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {},
  cultureItems: {},
  cultureItem: {
    padding: theme.spacing(2),
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      '&:nth-child(odd)': {
        textAlign: 'right',
        borderRight: '3px solid #ccc',
        '& $dot': {
          position: 'absolute',
          [theme.breakpoints.up('md')]: {
            borderRadius: '100%',
            border: '5px solid white',
            backgroundColor: '#ccc',
            padding: 6,
            top: 9,
            right: -28,
          },
        },
      },
    },
  },
  title: {
    position: 'relative',
  },
  description: {},
  dot: {},
}));

export default CultureItems;
