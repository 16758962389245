import React from 'react';
import { services } from '../../data/services';

import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';

import BasePage from './BasePage';

const service: any = services.find(
  s => s.title === 'International Moving Service',
);

const InternationalMovingService: React.FC = () => {
  // const classes = useStyles();

  return (
    <BasePage title={service.title}>
      <Typography>
        {service.description} From former residence to new home, we
        provide seamless door-to-door services beyond country borders and make
        your move as smooth as possible.
      </Typography>
      <ul>
        <li>Picking-up</li>
        <li>Storage</li>
        <li>Transportation via air, sea, rail, road and intermodal</li>
        <li>Custom clearance</li>
        <li>Insurance</li>
        <li>Delivery</li>
      </ul>
    </BasePage>
  );
};

// const useStyles = makeStyles((theme: any) => ({
//   root: {},
// }));

export default InternationalMovingService;
