import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ location }) {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.scrollTop = 0;
    }
  }, [location]);
  return null;
}

export default withRouter(ScrollToTop);
