import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { services } from '../../data/services';

const Header: React.FC<any> = ({ title }) => {
  const classes = useStyles();

  const service = services.filter((service: any) => service.title === title)[0];

  return (
    <div className="root">
      <div className={classes.header}>
        <div
          className={classes.background}
          style={{
            background: `url("${service.imgUrl}") no-repeat ${
              service.imgPosition ? service.imgPosition : 'center center'
            } white`,
            backgroundSize: 'cover',
          }}
        />
        <div className={classes.overlay} />
        <div className={classes.text}>
          <Typography className={classes.title}>{service.title}</Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  header: {
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    textAlign: 'left',
    backgroundSize: 'cover',
    borderRadius: 0,
    padding: theme.spacing(24, 8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 2),
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgb(50,50,150)',
    opacity: 0.2,
    zIndex: -1,
    transition: '1s ease',
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -2,
  },
  text: {
    maxWidth: 1000,
    margin: 'auto',
  },
  title: {
    fontSize: '5rem',
    fontWeight: 'bold',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  description: {
    fontSize: '1.5rem',
    color: 'white',
  },
}));

export default Header;
